<template>
    <v-container fluid>

        <h3><v-icon @click="goBackToPreviousPage">mdi-chevron-left</v-icon>Charging story</h3>
        <br>

        <div v-if="data" class="text-detail">
            <span>{{ data.chargerModelName }}-{{ data.serialNumber }}-{{ data.qrCode }}</span>
        </div>
        <div v-if="items">
            <div v-for="item in items" :key="item._id">
                <v-card class="card">
                    <v-card-text>
                        <v-row>
                            <v-col cols="6" class="text-detail-left">
                                <span>Transaction ID</span>
                            </v-col>
                            <v-col cols="6" class="text-detail-right">
                                <span>{{ getTransactionID(item) }}</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" class="text-detail-left">
                                <span>Date</span>
                            </v-col>
                            <v-col cols="6" class="text-detail-right">
                                <span>{{ getDateTransactionDate(item) }}</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" class="text-detail-left">
                                <span>Time</span>
                            </v-col>
                            <v-col cols="6" class="text-detail-right">
                                <span>{{ getTimeTransactionDate(item) }}</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" class="text-detail-left">
                                <span>Duration</span>
                            </v-col>
                            <v-col cols="6" class="text-detail-right">
                                <span>{{ countDuration(item) }}</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" class="text-detail-left">
                                <span>Energy Delivered</span>
                            </v-col>
                            <v-col cols="6" class="text-detail-right">
                                <span>{{ countEnergyDelivered(item) }}</span>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <br>
            </div>
        </div>



        <loading v-else-if="initializeLoading"></loading>
        <div class="red--text text-center" v-else-if="initializeError">
            {{ initializeError }}
        </div>
        <div class="text-center" v-else>
            Charging Station or Connector not found
        </div>

    </v-container>
</template>
  
<script>
import Vue from "vue";
import Loading from "@/components/Loading.vue";
import coreApiClient from "@/services/apis/coreApiClient";
import stateManager from "@common/services/stateManager";

export default Vue.extend({
    components: { Loading },
    data() {
        return {
            initializeLoading: false,
            initializeError: null,
            data: null,
            items: null,
            dataStatus: null
        };
    },
    methods: {
        getTransactionID(dataStatus) {
            return dataStatus?.details?.rawMeterValues?.meterValues?.transactionId || 'N/A';
        },
        getDateTransactionDate(dataStatus) {
            const dateTransaction = new Date(dataStatus?.details?.timestamp);
            const day = dateTransaction.getDate();
            const month = dateTransaction.getMonth() + 1;
            const year = dateTransaction.getFullYear();
            return `${year}-${month}-${day}`;
        },
        getTimeTransactionDate(dataStatus) {
            const dateTransaction = new Date(dataStatus.details.timestamp);
            const dateNow = new Date(dataStatus?.details.lastConsumption?.timestamp || new Date().getTime());
            let hours = dateTransaction.getHours();
            let hoursNow = dateNow.getHours();
            const minutes = dateTransaction.getMinutes();
            const minutesNow = dateNow.getMinutes();
            if (hours >= 12) {
                hours -= 12;
            }
            if (hoursNow >= 12) {
                hoursNow -= 12;
            }
            const ampm = hours >= 12 ? 'pm' : 'am';
            const ampmNow = hoursNow >= 12 ? 'pm' : 'am';


            return `${hours}:${minutes} ${ampm} - ${hoursNow}:${minutesNow} ${ampmNow}`;
        },
        countEnergyDelivered(dataStatus) {
            return ((dataStatus?.details?.currentTotalConsumptionWh || 0) / 1000).toFixed(2) + ' kWh';
        },
        countDuration(dataStatus) {
            const dateTransaction = new Date(dataStatus.details.timestamp);
            const dateNow = new Date(dataStatus?.details.lastConsumption?.timestamp || new Date().getTime());
            const durationInMs = dateNow - dateTransaction;
            const hours = Math.floor(durationInMs / 3600000);
            const minutes = Math.floor((durationInMs % 3600000) / 60000);

            if (hours === 0) {
                return `${minutes} minutes`;
            } else {
                return `${hours} hours, ${minutes} minutes`;
            }
        },
        async initialize() {
            const id = this.$route.params.id;
            const connectorId = this.$route.params.connectorId;
            this.initializeLoading = true;

            const objCharger = stateManager.get("objCharger");
            const keyChargingStations = id + ':' + connectorId;
            if (objCharger && Object.keys(objCharger).indexOf(keyChargingStations) > -1) {
                const charger = objCharger[keyChargingStations]

                const result = await coreApiClient.callRaw("chargingSessions", "publicFindAll", {
                    id: charger.id,
                    connectorId: charger.connectorId,
                    limit: 5,
                    orderBy: 'startTime',
                    orderType: 'desc',
                }, null, false, keyChargingStations);
                if (result && result.items) {
                    this.items = result.items
                }
            } else {
                console.log(false)
            }

            this.initializeLoading = false;
        },
        goBackToPreviousPage() {
            this.$router.push(`/app/my_charger/home_m`);
        },
    },
    async created() {
        await this.initialize();
    },
});
</script>
<style scoped>
.h3 {
    margin-left: 8px;
    /* Khoảng cách giữa biểu tượng mũi tên và tiêu đề */
    display: inline;
}

.icon-title {
    display: inline-flex;
    align-items: center;
    /* Căn chỉnh các phần tử theo chiều dọc */
}


.submit-btn-row {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    /* Khoảng cách từ lề dưới của màn hình */
    justify-content: space-around
}

.submit-btn {
    border: 1px solid;
    /* Thiết lập viền */
    border-radius: 4px;
    /* Định dạng các góc của nút */
}

.submit-btn-span {
    color: #fff !important;
}

.center-svg {
    display: block;
    /* Đảm bảo ảnh nằm trên một dòng */
    margin: 0 auto;
    /* Căn giữa theo chiều ngang */
}

.caption-size {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #333333;
}

.caption-false {

    text-align: left;
}

.caption-size-false {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;


    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 24px;
}

.image-detail {
    width: 153px;
    height: 151.05px;
    margin-left: auto;
    margin-right: auto;
}

.text-detail {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
}

.text-detail-left {
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
}

.text-detail-right {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
}
</style>
  